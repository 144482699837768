import axios from "axios";

export const axiosInstance = axios.create({
    baseURL:
        process.env.NODE_ENV === "production"
            ? "https://api.bornes.roulemarcel.fr"
            : "http://localhost:1337",
    timeout: 5000,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

// intercepteur de réponse
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            window.location.href = "/auth/connexion"; // Redirige vers la page de connexion
        }
        return Promise.reject(error); // Propager l'erreur pour une gestion ultérieure si nécessaire
    }
);
