import { lazy } from "react";

import MainLayout from "../layouts/MainLayout";
import { ProtectedRoutes } from "./ProtectedRoutes";

const Tours = lazy(() => import("../views/account/Tours"));
const Profil = lazy(() => import("../views/account/Profil"));
const Settings = lazy(() => import("../views/account/Settings"));
const Machines = lazy(() => import("../views/account/Machines"));
const MyTour = lazy(() => import("../views/account/MyTour"));

export const UserRoutes = {
    path: "/mon-compte",
    element: <MainLayout />,
    children: [
        {
            element: <ProtectedRoutes />,
            children: [
                {
                    path: "",
                    element: <Profil />,
                },
                {
                    path: "mes-infos",
                    element: <Settings />,
                },
                {
                    path: "mes-machines",
                    element: <Machines />,
                },
                {
                    path: "mes-tournees",
                    element: <Tours />,
                },
                {
                    path: "mes-tournees/:slug",
                    element: <MyTour />,
                },
            ],
        },
    ],
};
