import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const userStore = (set, get) => ({
    user: null,
    setUser: (user) => {
        set(() => ({ user }));
    },
    clearUser: () => {
        set(() => ({ user: null }));
    },
    getUser: () => get().user,
});

const useUserStore = create(
    persist(userStore, {
        name: "user",
        storage: createJSONStorage(() => localStorage),
    })
);

export default useUserStore;
