import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";

export default function AuthLayout() {
  return (
    <div className="flex flex-col gap-6">
      <Suspense
        fallback={
          <div className="fixed w-full h-full flex justify-center items-center">
            <div className="loader" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
      <Footer />
    </div>
  );
}