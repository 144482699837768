import { Link, useNavigate } from "react-router-dom";
import packages from "../../package.json";
import { logout } from "../api/Authentication";
import userStore from "../contexts/UserStore";

export default function Header() {
    const user = userStore((state) => state.getUser);
    const clearUser = userStore((state) => state.clearUser);

    const navigate = useNavigate();

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            const data = await logout();
            if (data) {
                clearUser();
                navigate("/auth/connexion");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <header className="sticky top-0 z-50 bg-base-200 flex justify-between items-center py-2 px-[64px]">
            {/* <Link to="/" className="">
                <svg
                    width="64px"
                    height="64px"
                    viewBox="0 0 1024 1024"
                    class="icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M511.6802 511.64822m-511.6802 0a511.6802 511.6802 0 1 0 1023.3604 0 511.6802 511.6802 0 1 0-1023.3604 0Z"
                            fill="#EDD87E"
                        ></path>
                        <path
                            d="M511.6802-0.03198c-282.60737 0-511.6802 229.072829-511.6802 511.6802s229.072829 511.6802 511.6802 511.6802 511.6802-229.072829 511.6802-511.6802-229.072829-511.6802-511.6802-511.6802z m0 959.400375c-247.269457 0-447.720175-200.450718-447.720175-447.720175s200.450718-447.720175 447.720175-447.720175 447.720175 200.450718 447.720175 447.720175-200.450718 447.720175-447.720175 447.720175z"
                            fill=""
                        ></path>
                        <path
                            d="M319.800125 255.80812m-63.960025 0a63.960025 63.960025 0 1 0 127.92005 0 63.960025 63.960025 0 1 0-127.92005 0Z"
                            fill="#EF4D4D"
                        ></path>
                        <path
                            d="M447.720175 511.64822m-63.960025 0a63.960025 63.960025 0 1 0 127.92005 0 63.960025 63.960025 0 1 0-127.92005 0Z"
                            fill="#EF4D4D"
                        ></path>
                        <path
                            d="M223.860087 639.56827m-63.960025 0a63.960025 63.960025 0 1 0 127.92005 0 63.960025 63.960025 0 1 0-127.92005 0Z"
                            fill="#EF4D4D"
                        ></path>
                        <path
                            d="M543.660212 799.468332m-63.960025 0a63.960025 63.960025 0 1 0 127.92005 0 63.960025 63.960025 0 1 0-127.92005 0Z"
                            fill="#EF4D4D"
                        ></path>
                        <path
                            d="M799.500312 607.588257m-63.960025 0a63.960025 63.960025 0 1 0 127.92005 0 63.960025 63.960025 0 1 0-127.92005 0Z"
                            fill="#EF4D4D"
                        ></path>
                        <path
                            d="M671.580262 287.788132m-63.960025 0a63.960025 63.960025 0 1 0 127.92005 0 63.960025 63.960025 0 1 0-127.92005 0Z"
                            fill="#EF4D4D"
                        ></path>
                        <path
                            d="M575.640225 431.698189v63.960025a15.990006 15.990006 0 0 0 31.980012 0v-63.960025a15.990006 15.990006 0 0 0-31.980012 0zM335.790131 703.528295a15.990006 15.990006 0 0 0-15.990006 15.990006v63.960025a15.990006 15.990006 0 0 0 31.980012 0v-63.960025a15.990006 15.990006 0 0 0-15.990006-15.990006zM881.017364 474.999126l-45.219738-45.219738a15.990006 15.990006 0 0 0-22.609868 22.609869l45.219737 45.219737a15.990006 15.990006 0 0 0 22.609869-22.609868zM452.293317 301.219738a15.990006 15.990006 0 0 0-22.609869 22.609868l45.219738 45.219738a15.990006 15.990006 0 0 0 22.609868-22.609869l-45.219737-45.219737zM676.761024 684.244347a15.990006 15.990006 0 0 0-22.609868 22.609869l45.219737 45.219738a15.990006 15.990006 0 0 0 22.609869-22.609869l-45.219738-45.219738zM207.870081 383.72817h-63.960025a15.990006 15.990006 0 0 0 0 31.980012h63.960025a15.990006 15.990006 0 0 0 0-31.980012zM592.685572 174.770768a15.990006 15.990006 0 0 0-22.609869 0l-45.219738 45.219738a15.990006 15.990006 0 0 0 22.609869 22.609869l45.219738-45.219738a15.958026 15.958026 0 0 0 0-22.609869zM601.735915 590.862711l-45.219738 45.219737a15.990006 15.990006 0 0 0 22.609869 22.609869l45.219738-45.219737a15.990006 15.990006 0 0 0-22.609869-22.609869zM409.98376 814.530918l-45.219738 45.219738a15.990006 15.990006 0 0 0 22.609869 22.609869l45.219738-45.219738a15.990006 15.990006 0 0 0-22.609869-22.609869z"
                            fill="#3AAD73"
                        ></path>
                        <path
                            d="M703.560275 479.668207m-31.980013 0a31.980012 31.980012 0 1 0 63.960025 0 31.980012 31.980012 0 1 0-63.960025 0Z"
                            fill="#4F3923"
                        ></path>
                        <path
                            d="M735.540287 831.448345m-31.980012 0a31.980012 31.980012 0 1 0 63.960025 0 31.980012 31.980012 0 1 0-63.960025 0Z"
                            fill="#4F3923"
                        ></path>
                        <path
                            d="M447.720175 671.548282m-31.980013 0a31.980012 31.980012 0 1 0 63.960025 0 31.980012 31.980012 0 1 0-63.960025 0Z"
                            fill="#4F3923"
                        ></path>
                        <path
                            d="M191.880075 511.64822m-31.980013 0a31.980012 31.980012 0 1 0 63.960025 0 31.980012 31.980012 0 1 0-63.960025 0Z"
                            fill="#4F3923"
                        ></path>
                        <path
                            d="M319.800125 415.708182m-31.980013 0a31.980012 31.980012 0 1 0 63.960025 0 31.980012 31.980012 0 1 0-63.960025 0Z"
                            fill="#4F3923"
                        ></path>
                        <path
                            d="M447.720175 159.868082m-31.980013 0a31.980012 31.980012 0 1 0 63.960025 0 31.980012 31.980012 0 1 0-63.960025 0Z"
                            fill="#4F3923"
                        ></path>
                        <path
                            d="M831.480325 319.768145m-31.980013 0a31.980012 31.980012 0 1 0 63.960025 0 31.980012 31.980012 0 1 0-63.960025 0Z"
                            fill="#4F3923"
                        ></path>
                    </g>
                </svg>
            </Link> */}
            <Link to="/" className="flex">
                <h1 className="text-center text-4xl font-bold py-4">
                    Stock & Go
                </h1>
            </Link>
            {!user() ? (
                <div className="flex gap-4">
                    <Link
                        to="/auth/connexion"
                        className="flex bg-primary text-white p-2 rounded-lg"
                    >
                        Se connecter
                    </Link>
                    {/* <Link to="/auth/inscription" className="flex">
                        Créer un compte
                    </Link> */}
                </div>
            ) : (
                <div className="flex gap-2 justify-center items-center">
                    <Link to="/mon-compte" className="flex">
                        Mon compte
                    </Link>
                    <button
                        className="flex bg-primary text-white p-2 rounded-lg"
                        onClick={(e) => handleLogout(e)}
                    >
                        Se déconnecter
                    </button>
                </div>
            )}
            <div className="badge badge-xs fixed top-1 right-1">
                v{packages.version}
            </div>
        </header>
    );
}
