import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="text-white flex justify-center items-center py-2">
      <p>
        Développé avec ☕ par{" "}
        <Link className="underline" to="https://www.roulemarcel.fr/" target="_blank">
          RouleMarcel
        </Link>
      </p>
    </footer>
  );
}
