import { axiosInstance } from "../utils/axiosConfig";

export const login = async (email, password) => {
    try {
        const response = await axiosInstance.post("/auth/login", {
            email,
            password,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching login:", error);
    }
};

export const isLoggedIn = async () => {
    try {
        const response = await axiosInstance.get("/auth/verify");
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const logout = async () => {
    try {
        const response = await axiosInstance.get("/auth/logout");
        return response.data;
    } catch (error) {
        console.error("Error fetching logout:", error);
    }
};

export const signUp = async (data) => {
    try {
        const response = await axiosInstance.post("/auth/signup", data);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};
