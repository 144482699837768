import { useRoutes } from "react-router-dom";
import { MainRoutes } from "./MainRoutes";
import { Navigate } from "react-router-dom";
import { AuthenticationRoutes } from "./AuthenticationRoutes";
import { UserRoutes } from "./UserRoutes";

export default function ThemeRoutes() {
  return useRoutes([
    // { path: "/admin", element: <Navigate to="/admin/board" replace /> },
    // {path: "/", element: <Navigate to="/auth/connexion" replace />},
    // {path: "/stock", element: <Navigate to="/auth/connexion" replace />},
    // {path: "/auth", element: <Navigate to="/auth/connexion" replace />},
    AuthenticationRoutes,
    UserRoutes,
    MainRoutes,
  ]);
}
