import { lazy } from "react";

import AuthLayout from "../layouts/AuthLayout";

const Login = lazy(() => import("../views/auth/Login"));
const Register = lazy(() => import("../views/auth/Register"));

export const AuthenticationRoutes = {
    path: "/auth",
    element: <AuthLayout />,
    children: [
        {
            path: "connexion",
            element: <Login />,
        },
        {
            path: "inscription",
            element: <Register />,
        },
    ],
};